import React, { useState } from 'react'
import { EnsurePagePermission } from '../../components/auth'
import Layout from '../../components/layout/layout'
import Footer from '../../components/layout/footer'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import { graphql } from 'gatsby'
import { Container, Row } from 'react-bootstrap'
import { GatsbySeo } from 'gatsby-plugin-next-seo'

export default function Docs({data}) {

  const [profile, setProfile] = useState()
  const [isAllowed, setIsAllowed] = useState(false)
  EnsurePagePermission('docs', setIsAllowed, setProfile)

  return (
    isAllowed &&
    <Layout>
      <GatsbySeo
        titleTemplate='Docs | %s'
      />
      <div className="main-container">
        <header className="text-white">
          <div className="d-flex justify-content-center sm-adjust">
            <h1>{data.allFlamelinkDocsContent.nodes[0].title}</h1>
          </div>
        </header>
        <section>
          <Container className="col-10 col-sm-10 col-lg-6 col-xl-5">
            <Row className="py-4" style={{display: 'inherit'}}>
              <ReactMarkdown plugins={[gfm]} children={data.allFlamelinkDocsContent.nodes[0].content} />
            </Row>
          </Container>
        </section>
        <Footer />
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allFlamelinkDocsContent {
    nodes {
      title
      content
    }
  }
}
`
