import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Row, Container } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faMedium, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import i18next from 'i18next'

export default function Footer() {

  const data = useStaticQuery(graphql`
    query FooterQuery {
      footer: allStoryblokEntry(filter: {slug: {eq: "footer"}})  {
        edges {
          node {
            content
          }
        }
      }
    }
  `)

  // l10n
  let storyPage

  if (i18next.language === 'cs') {
    storyPage = JSON.parse(data.footer.edges[0].node.content)
  } else {
    storyPage = JSON.parse(data.footer.edges[1].node.content)
  }

  return (
    <footer className="page-footer font-small mt-auto footer-menu">
      <Container>
        <Row>
          <div className="col-md-12 py-3">
            <div className="mb-2 flex-center">
              <a href="mailto: info@pinavo.cz"><FontAwesomeIcon icon={faEnvelope} color="white"/> {storyPage.blocks[0].title}</a>
            </div>
          </div>
        </Row>
        <Row>
          <div className="col-md-12 py-3">
            <div className="mb-2 flex-center">
              <a href="https://www.instagram.com/pinavomaps/" className="me-md-5 me-3"><FontAwesomeIcon icon={faInstagram} size="2x" color="#3771c8" aria-label="Go to Instagram"/></a>
              <a href="https://www.twitter.com/pinavomaps/" className="me-md-5 me-3"><FontAwesomeIcon icon={faTwitter} size="2x" color="#3771c8" aria-label="Go to Twitter"/></a>
              <a href="https://www.linkedin.com/company/pinavo" className="me-md-5 me-3"><FontAwesomeIcon icon={faLinkedin} size="2x" color="#3771c8" aria-label="Go to LinkedIn"/></a>
              <a href="https://medium.com/pinavo"><FontAwesomeIcon icon={faMedium} size="2x" color="#3771c8" aria-label="Go to Medium"/></a>
            </div>
          </div>
        </Row>
        <Row className="justify-content-center">
          <div className="footer-copyright text-center py-3 text-white" style={{backgroundColor: '#0b182a'}}> <a href="/terms-and-conditions">{storyPage.blocks[2].title}</a> | <a href="/privacy-policy">{storyPage.blocks[3].title}</a></div>
        </Row>
        <Row className="justify-content-center">
          <div className="footer-copyright text-center py-3 text-white" style={{backgroundColor: '#0b182a'}}> {storyPage.blocks[4].title}</div>
        </Row>
      </Container>
    </footer>
  )
}
