import { useEffect, useState, useRef } from 'react'
import { firestore, useAuth } from 'gatsby-theme-firebase'
import { navigate } from 'gatsby'

export function GetLoginStatus(setIsLoggedIn, setProfile) {

  const {isLoading, isLoggedIn, profile} = useAuth()

  // useRef hook for storing mutable object - skipping initial render of useEffect hook
  const initialRender = useRef(true)

  useEffect(() => {
    // console.log('useEffect rendering')

    // skipping initial render - preventing rendering twice (re-render only on dependency state change)
    if (initialRender.current) {
      initialRender.current = false
      return
    }

    // check if authentication is done - then run authorization
    if (isLoading) {
      // console.log('loading auth')
    } else {
      setIsLoggedIn(isLoggedIn)
      if (isLoggedIn) {
        setProfile(profile)
      }
    }

  }, [isLoading])
}

export function EnsurePagePermission(permission, setIsAllowed, setProfile) {

  const {isLoading, isLoggedIn, profile} = useAuth()
  const [isChecking, setIsChecking] = useState(true)

  // reference on firestore collections
  const usersRef = firestore.collection('pin_users')
  const rolesRef = firestore.collection('pin_roles')

  // useRef hook for storing mutable object - skipping initial render of useEffect hook
  const initialRender = useRef(true)

  useEffect(() => {

    // console.log('useEffect rendering')

    // skipping initial render - preventing rendering twice (re-render only on dependency state change)
    if (initialRender.current) {
      initialRender.current = false
      return
    }

    // check if authentication is done - then run authorization
    if (isLoading) {
      // console.log('loading auth')
    } else {

      // if user is not logged in, go to login page; otherwise check permissions
      if (!isLoggedIn) {
        // console.log ('user not logged in')
        navigate('/login')
      } else {
        // console.log('user logged in, auth loading status: ' + isLoading)
        setProfile(profile)
        // get user role
        usersRef.doc(profile.uid).get().then((doc) => {
          if (!doc.exists) {
            // cover situation when saving user is delayed.. improvement in future needed
            navigate('/')
          } else {
            const role = doc.data().role
            // console.log('loading role: ' + role )

            // get permissions for role
            rolesRef.doc(role).get().then((doc) => {
              const perm = doc.data().pagePermissions
              // console.log('loading permissions: ' + perm)

              // if array contains a given permission, allow acces - otherwise redirect to login page
              if (perm.includes(permission)) {
                setIsAllowed(true)
                // console.log('permission passed: ' + permission)
                return
              } else {
                // console.log('access denied.. redirecting')
                navigate('/404')
              }
            })
          }
        })
      }
    }

    setIsChecking(false)
    // console.log('checking permissions: ' + isChecking)

  }, [isLoading])

}
